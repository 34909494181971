import { OPTIMIZELY_SCRIPT_URL } from "./constants";

const init = (scriptSrcUrl: string): void => {
  const body = document.getElementsByTagName("body")[0];
  const script = document.createElement("script");

  script.type = "text/javascript";
  script.setAttribute("defer", "defer");
  script.src = scriptSrcUrl;

  body.appendChild(script);
};

export const inject = () => {
  init(OPTIMIZELY_SCRIPT_URL);
};
